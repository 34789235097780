<template>
  <div>
    <div
      style="
        height: 32px;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 32px;
      "
    >
      <div style="float: left;">
        <div style="font-size: 12px; margin-top: -8px;">
          自动刷新周期：
          <b v-show="!setRefreshFlag">{{ refreshTime ?  refreshTime === 60 ? 1 : refreshTime : '不自动刷新'}}</b>
          <a-radio-group v-show="setRefreshFlag" v-model="selectRefreshTime" style="background: #fff;">
            <a-radio :value="0">不刷新</a-radio>
            <a-radio :value="5">5秒</a-radio>
            <a-radio :value="10">10秒</a-radio>
            <a-radio :value="15">15秒</a-radio>
            <a-radio :value="30">30秒</a-radio>
            <a-radio :value="60">1分</a-radio>
          </a-radio-group>
          <span v-if="refreshTime">{{ refreshTime === 60 ? '分' : '秒' }}</span>
          <a v-show="!setRefreshFlag" @click="handleSetting" style="margin-left: 8px;">设置</a>
          <a-space :size="6" v-show="setRefreshFlag">
            <a  @click="handleOk" style="margin-left: 8px;">确定</a>
            <a  @click="handleCancel" style="margin-left: 8px;">取消</a>
          </a-space>
        </div>
        <div style="font-size: 12px; margin-top: -8px;">上次刷新时间：{{ refreshDate }}</div>
      </div>
      <a-space size="middle" style="float: right">
        <a-input-search
          placeholder="请输入关键词"
          @search="search"
        ></a-input-search>
        <reload-button @click="fetch"></reload-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      row-key="id"
      :scroll="{ x: scrollX }"
      :components="components"
      style="margin-bottom: 16px"
      class="custom-table scroll-hidden-table"
    >
      <template slot="name" slot-scope="text, record">
        <a-tooltip v-if="record.error" title="无法获取数据" style="margin-right: 4px">
          <a-icon theme="filled" type="exclamation-circle" style="color: #f8593e"></a-icon>
        </a-tooltip>
        <a-tooltip placement="topLeft" :title="text">
          <a
            v-if="record.value_type === 'numeric_unsigned' || record.value_type === 'numeric_float'"
            @click="
            $refs.chartModal.show({
              title: text,
              key: record.key
            })
          "
          >
            {{ text }}
          </a>
          <a v-else @click="info(record)">{{ text }}</a>
        </a-tooltip>
      </template>
      <template slot="valueType" slot-scope="text">
        {{ $t(`value_type.${text}`) }}
      </template>
      <template slot="value" slot-scope="text, record">
        <a-tooltip :title="`${text} ${record.unit}`">
          {{ `${text} ${record.unit}` }}
        </a-tooltip>
      </template>
    </a-table>

    <div style="height: 32px">
      <a-pagination
        v-model="form.page"
        :pageSize="form.pageSize"
        :show-size-changer="true"
        :show-total="t => `共 ${t} 条`"
        :total="total"
        @change="
          page => {
            this.form.page = page
            this.fetch()
          }
        "
        @showSizeChange="
          (_, pageSize) => {
            this.form.page = 1
            this.form.pageSize = pageSize
            this.fetch()
          }
        "
        style="float: right"
      ></a-pagination>
    </div>

    <multi-line-chart-modal
      ref="chartModal"
      :history-func="historyFunc"
      :source-id="sourceId"
    ></multi-line-chart-modal>
  </div>
</template>

<script>
import moment from 'moment'
import ReloadButton from '@/components/button/ReloadButton.vue'
import MultiLineChartModal from '@/components/modal/MultiLineChartModal/index.vue'

export default {
  name: 'MonitorItemTable',
  components: {
    MultiLineChartModal,
    ReloadButton
  },
  props: {
    getFunc: {
      type: Function,
      required: true
    },
    historyFunc: {
      type: Function,
      required: true
    },
    sourceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      form: {
        name: '',
        page: 1,
        pageSize: 20
      },
      columns: [
        {
          dataIndex: 'name',
          title: '名称',
          width: 180,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'value_type',
          title: '类型',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'valueType'
          }
        },
        {
          dataIndex: 'delay',
          title: '采集间隔',
          width: 100
        },
        {
          dataIndex: 'created_at',
          title: '上次采集时间',
          width: 200
        },
        {
          dataIndex: 'value',
          title: '上次采集值',
          width: 320,
          ellipsis: true,
          scopedSlots: {
            customRender: 'value'
          }
        }
      ],
      dataSource: [],
      total: 0,
      loading: false,
      timer: null,
      setRefreshFlag: false,
      refreshTime: 0,
      selectRefreshTime: 0,
      refreshDate: undefined,
      components: {
        header: {}
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.name) params.name = this.form.name
      this.loading = true
      this.getFunc(this.sourceId, params)
        .then(res => {
          const data = res.data
          this.dataSource = data.data
          this.total = data.total * 1
          this.refreshDate = moment().format('yyyy-MM-DD HH:mm:ss')
        })
        .finally(() => {
          this.loading = false
        })
    },
    search (value) {
      this.form.name = value
      this.form.page = 1
      this.fetch()
    },
    info (item) {
      this.$info({
        title: item.name,
        content: <p>{ item.value }</p>,
        okText: '确定',
        width: 800
      })
    },
    autoRefresh (time) {
      if (!this.setRefreshFlag) {
        this.timer = setTimeout(() => {
          const params = {
            page: this.form.page,
            page_size: this.form.pageSize
          }
          if (this.form.name) params.name = this.form.name
          this.loading = true
          this.getFunc(this.sourceId, params)
            .then(res => {
              const data = res.data
              this.dataSource = data.data
              this.total = data.total * 1
              this.refreshDate = moment().format('yyyy-MM-DD HH:mm:ss')
              this.autoRefresh(time)
            })
            .finally(() => {
              this.loading = false
            })
        }, time)
      }
    },
    handleOk () {
      this.setRefreshFlag = false
      this.refreshTime = this.selectRefreshTime
      if (this.timer) clearTimeout(this.timer)
      if (this.refreshTime) {
        this.autoRefresh(this.refreshTime * 1000)
      }
    },
    handleCancel () {
      this.setRefreshFlag = false
      this.selectRefreshTime = 0
      if (this.timer) clearTimeout(this.timer)
      if (this.refreshTime) {
        this.autoRefresh(this.refreshTime * 1000)
      }
    },
    handleSetting () {
      this.setRefreshFlag = true
      if (this.timer) clearTimeout(this.timer)
    }
  }
}
</script>
