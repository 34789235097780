<template>
  <a-tag :color="color">{{ name }}</a-tag>
</template>

<script>
import { greenColor, redColor, grayColor } from '@/utils/const'

export default {
  name: 'MonitorStatusTag',
  props: {
    name: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    color () {
      if (this.status === 'up') return greenColor
      else if (this.status === 'down') return redColor
      return grayColor
    }
  }
}
</script>
